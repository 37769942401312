import React, { useEffect, useRef, useState } from "react";
import {
  motion,
  useScroll,
  useTransform,
  AnimatePresence,
} from "framer-motion";
import {
  Bars3Icon,
  XMarkIcon,
  ArrowUpRightIcon,
  ArrowRightIcon,
} from "@heroicons/react/24/outline";
import Header from "./Header";
import ProgressBar from "./ProgressBar";
import Section from "./Section";
import Footer from "./Footer";
import BgAnimation from "./BgAnimation";
import { debounce } from "./utils";
import DecentralizedAdExchange from "./sections/DecentralizedAdExchange";
import WhatIsAdX from "./sections/WhatIsAdX";
import ForPublishers from "./sections/ForPublishers";
import ForAdvertisers from "./sections/ForAdvertisers";
import Hackathon from "./sections/Hackathon";
import WhatWeBuilt from "./sections/WhatWeBuilt";
import WhatIsAnAdExchange from "./sections/WhatIsAnAdExchange";
import Team from "./sections/Team";
import Explorer from "./sections/Explorer";
import { useNavigate } from "react-router-dom"; // Update this import

const LandingPage: React.FC = () => {
  const navigate = useNavigate(); // Initialize navigate
  const { scrollYProgress, scrollY } = useScroll();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [currentSection, setCurrentSection] = useState(-1);
  const [scrollDirection, setScrollDirection] = useState<"up" | "down">("down");
  const lastScrollPosition = useRef(0);
  const sectionRefs = useRef<(HTMLElement | null)[]>([]);

  const sections = [
    { Component: WhatIsAnAdExchange, id: "what-is-an-ad-exchange" },
    { Component: WhatIsAdX, id: "what-is-adx" },
    { Component: DecentralizedAdExchange, id: "decentralized-ad-exchange" },
    { Component: Explorer, id: "explorer" },
    { Component: ForPublishers, id: "for-publishers" },
    { Component: ForAdvertisers, id: "for-advertisers" },
    { Component: Hackathon, id: "hackathon" },
    { Component: WhatWeBuilt, id: "what-we-built" },
    { Component: Team, id: "team" },
  ];

  useEffect(() => {
    const handleScroll = debounce(() => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > lastScrollPosition.current) {
        setScrollDirection("down");
      } else if (scrollPosition < lastScrollPosition.current) {
        setScrollDirection("up");
      }
      lastScrollPosition.current = scrollPosition;
    }, 50);

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = sectionRefs.current.findIndex(
            (ref) => ref === entry.target
          );
          setCurrentSection(index);
          // Update URL hash when a new section is in view
          if (index > 0 && index <= sections.length) {
            navigate(`/#${sections[index - 1].id}`); // Use navigate here
          } else {
            navigate(window.location.pathname); // Use navigate here
          }
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    sectionRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => observer.disconnect();
  }, []);

  const scrollTo = (id: string) => {
    const element = document.getElementById(id);
    if (element != null) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleChevronClick = () => {
    scrollTo(sections[0].id);
  };

  const navBackgroundOpacity = useTransform(scrollY, [0, 100], [0, 1]);
  const logoOpacity = useTransform(scrollYProgress, [0, 0.1], [0, 1]);
  const progressBarOpacity = currentSection == -1 ? 0 : 1;

  return (
    <div className="custom-scrollbar-page relative bg-slate-900 h-screen overflow-y-auto snap-y snap-mandatory custom-scrollbar overflow-x-hidden">
      <BgAnimation />

      <div className="relative text-white  z-10">
        <motion.nav
          className="fixed top-0 left-0 right-0 z-50 backdrop-filter backdrop-blur-lg"
          style={{
            backgroundColor: useTransform(
              navBackgroundOpacity,
              [0, 1],
              ["rgba(0, 0, 0, 0)", "rgba(0, 0, 0, 0.5)"]
            ),
          }}
        >
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between h-16">
              <div className="flex items-center space-x-4">
                <motion.div style={{ opacity: logoOpacity }}>
                  <a
                    onClick={() => {
                      scrollTo("top");
                    }}
                    className="text-white font-bold text-xl"
                  >
                    <img
                      src="/LogoWhiteTransparent.svg"
                      alt="Logo"
                      className="h-16 -py-3 mr-3"
                    />
                  </a>
                </motion.div>
                <div className="hidden md:flex space-x-4 font-medium">
                  <button
                    onClick={() => scrollTo("for-publishers")}
                    className="text-white hover:text-gray-300"
                  >
                    For Publishers
                  </button>
                  <button
                    onClick={() => scrollTo("for-advertisers")}
                    className="text-white hover:text-gray-300"
                  >
                    For Advertisers
                  </button>
                  <button
                    onClick={() => scrollTo("hackathon")}
                    className="text-white hover:text-gray-300"
                  >
                    Hackathon
                  </button>
                  {/* <a href="/dashboard/explorer" target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-300 flex items-center">
                                        Explorer
                                        <ArrowUpRightIcon className="ml-1 h-4 w-4" />
                                    </a> */}
                </div>
              </div>
              <div className="hidden md:block">
                <a
                  href="https://www.getboost.so/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center px-4 py-2 border border-white text-sm font-medium rounded-md text-white hover:bg-white-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Demo
                  <ArrowUpRightIcon className="ml-2 -mr-1 h-4 w-4" />
                </a>
              </div>
              <div className="md:hidden">
                <button
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                  className="text-white"
                >
                  {isMenuOpen ? (
                    <XMarkIcon className="h-6 w-6" />
                  ) : (
                    <Bars3Icon className="h-6 w-6" />
                  )}
                </button>
              </div>
            </div>
          </div>
        </motion.nav>

        {/* Mobile menu drawer */}
        <motion.div
          className="fixed inset-y-0 right-0 max-w-xs w-full bg-slate-800 z-50 md:hidden"
          initial={{ x: "100%" }}
          animate={{ x: isMenuOpen ? 0 : "100%" }}
          transition={{ type: "tween" }}
        >
          <div className="flex flex-col p-4 h-full">
            <div className="flex justify-end mb-4">
              <button
                onClick={() => setIsMenuOpen(false)}
                className="text-white hover:text-gray-300 transition-colors duration-200"
              >
                <XMarkIcon className="h-6 w-6" />
              </button>
            </div>
            <div className="flex flex-col space-y-4 items-end">
              <button
                onClick={() => {
                  scrollTo("for-publishers");
                  setIsMenuOpen(false);
                }}
                className="text-white hover:text-gray-300 transition-colors duration-200"
              >
                For Publishers
              </button>
              <button
                onClick={() => {
                  scrollTo("for-advertisers");
                  setIsMenuOpen(false);
                }}
                className="text-white hover:text-gray-300 transition-colors duration-200"
              >
                For Advertisers
              </button>
              <button
                onClick={() => {
                  scrollTo("hackathon");
                  setIsMenuOpen(false);
                }}
                className="text-white hover:text-gray-300 transition-colors duration-200"
              >
                Hackathon
              </button>
              {/* <a
                href="/explorer"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-gray-300 transition-colors duration-200 flex items-center"
              >
                Explorer
                <ArrowUpRightIcon className="ml-1 h-4 w-4" />
              </a> */}
              <a
                href="https://www.getboost.so/"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Demo
                <ArrowRightIcon className="ml-2 -mr-1 h-4 w-4" />
              </a>
            </div>
          </div>
        </motion.div>

        <AnimatePresence initial={false}>
          <Header
            currentSection={currentSection}
            scrollDirection={scrollDirection}
            onChevronClick={handleChevronClick}
            ref={(el) => (sectionRefs.current[0] = el)}
          />

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{ opacity: progressBarOpacity }}
            className="fixed right-8 top-1/2 transform -translate-y-1/2 z-[100]"
          >
            <ProgressBar
              currentSection={currentSection}
              totalSections={sections.length}
              onDotClick={(index) => {
                index == 0 ? scrollTo("top") : scrollTo(sections[index - 1].id);
              }}
            />
          </motion.div>

          {sections.map(({ Component, id }, index) => (
            <Section
              key={id}
              index={index + 1}
              currentSection={currentSection}
              scrollDirection={scrollDirection}
              ref={(el) => (sectionRefs.current[index + 1] = el)}
              id={id}
            >
              <Component />
            </Section>
          ))}

          <Footer
            currentSection={currentSection}
            totalSections={sections.length + 1}
            scrollDirection={scrollDirection}
            ref={(el) => (sectionRefs.current[sections.length + 1] = el)}
            id={"footer"}
          />
        </AnimatePresence>
      </div>
    </div>
  );
};

export default LandingPage;
